import { makeStyles } from '@material-ui/core'


export default makeStyles(
    ( theme ) => ({
      root: {
        [theme.breakpoints.up( 'sm' )]: {}
      },

      cardRoot: {
        width: '100%',
        position: 'relative',
        margin: theme.spacing( 6, 0 ),
        [theme.breakpoints.up( 'sm' )]: {
          width: 400,
          margin: theme.spacing( 4, 2 ),
          cursor: 'pointer'
        }
      },

      titulo: {
        maxWidth: '80%',
        position: 'absolute',
        top: '15%',
        left: 0,
        paddingLeft: theme.spacing( 2 ),
        zIndex: 20,
        '& span': {
          fontWeight: 'bold',
          fontSize: theme.typography.h5.fontSize,
          fontFamily: 'Fira sans',
          color: theme.palette.text.lightText
        }
      },

      imageContainer: {
        position: 'relative'

      },

      productImage: {
        borderRadius: theme.roundnessSmall,
        width: '100%',
        background: 'white',
        [theme.breakpoints.up( 'sm' )]: {
          height: 300
        }

      },

      overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.overlays.overlay,
        zIndex: 10,
        borderRadius: theme.roundnessSmall,
        [theme.breakpoints.up( 'sm' )]: {
          height: 300
        }
      },

      resumen: {
        margin: theme.spacing( 1 ),
        textAlign: 'center',
        color: theme.palette.text.lightText
      }
    }) )
