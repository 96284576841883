// noinspection JSUnresolvedVariable

import { Grid } from '@material-ui/core'
import { graphql } from 'gatsby'
import React from 'react'
import { Layout } from '../components/_layout'
import { Contacto } from '../components/Contacto'
import { Clientes, Nosotros, VideoSection } from '../components/Inicio'
import { Galeria } from '../components/Nosotros'


export default function NosotrosPage({ data }) {
  const imagenes = data.nosotrosData.edges[0].node.imagenes
  const logos = data.clientesData.edges
  const alianzas = data.alianzasData.edges

  return (
      <Layout
          withNav
          seoTitle="Nosotros"
          seoDescription="¿Quiénes somos?"
      >
        <Grid style={{ height: 64, width: '100%' }} />
        <Nosotros />
        <Clientes {...{ logos }} />
        <Clientes
            alianza
            logos={alianzas}
        />
        <VideoSection videoURL={'https://www.youtube.com/embed/9o5HdvD8YVU'} />
        <VideoSection videoURL={'https://www.youtube.com/embed/mgnglSRD_Fg'} />
        <Galeria {...{ imagenes }} />
        <Contacto />
      </Layout>
  )
}

export const NosotrosPageQuery = graphql`
    query NosotrosPageQuery {
        nosotrosData: allContentfulNosotrosGaleria {
            edges {
                node {
                    id
                    imagenes {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            quality: 100
                        )
                    }
                }
            }
        }

        clientesData: allContentfulCliente {
            edges {
                node {
                    id
                    nombre
                    logotipo {
                        gatsbyImageData(width: 88, placeholder: TRACED_SVG, quality: 100)
                    }
                }
            }
        }

        alianzasData: allContentfulAlianza {
            edges {
                node {
                    id
                    nombre
                    logotipo {
                        gatsbyImageData(width: 200, placeholder: TRACED_SVG, quality: 100)
                    }
                    urlExterna
                }
            }
        }
    }
`



