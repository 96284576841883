// noinspection DuplicatedCode

import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  root: {
    [theme.breakpoints.up( 'sm' )]: {

    }
  },

  galeria: {
    padding: theme.spacing(2, 0),
    justifyContent: 'center',
    alignItems: 'center'
  },


  imgGaleria: {
    borderRadius: theme.roundnessSmall,
    width: '100%',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up( 'sm' )]: {
      maxWidth: '30%',
      height: 300,
      margin: theme.spacing(1),
    }
  }
}) )
