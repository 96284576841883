// noinspection JSUnresolvedVariable

import React from 'react'

import { SectionContainer, SectionText, SectionTitle } from '../../_layout'
import SectionDivider from '../../_layout/SectionDivider/SectionDivider'
import theme from '../../../App.theme'

import useStyles from './styles'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Grid } from '@material-ui/core'


const textClientes = 'Para Neminatura es muy importante que nuestros clientes estén satisfechos con nuestro producto, con el fin de continuar ofertándolo en su mercado, garantizando un producto de la más alta calidad.\n' +
                     'Neminatura está siempre disponible para realizar alianzas comerciales, enriqueciendo la propuesta culinaria de nuevos clientes.'

const textAlianzas = ''

const Clientes = ( { logos, alianza } ) => {

  const styles = useStyles( { alianza } )

  const handleExternalLink = ( url ) => {
    if ( alianza && typeof window !== 'undefined' ) {
      const aTag = document.createElement( 'a' )
      aTag.rel = 'noopener'
      aTag.target = '_blank'
      aTag.href = url
      aTag.click()
    }

  }

  return (
      <SectionContainer sectionID={ alianza ? 'xAlianzas' : 'Clientes' }>
        <SectionTitle title={ alianza ? 'Alianzas' : 'Clientes' }/>
        <SectionText text={ alianza ? textAlianzas : textClientes }/>
        <Grid container className={ styles.logoContainer }>{
          logos.map( ( { node } ) => {
            return (
                <Grid key={ node.id } className={ styles.clickable }
                      onClick={ () => handleExternalLink( node.urlExterna ) }>
                  <GatsbyImage
                      alt={ node.nombre }
                      image={ node.logotipo.gatsbyImageData }
                      className={ styles.image }/>
                </Grid>
            )
          } )
        }</Grid>
        <SectionDivider color={ theme.palette.text.disabled }/>

      </SectionContainer>
  )
}

export default Clientes