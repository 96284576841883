import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  root: {
    [theme.breakpoints.up( 'sm' )]: {}
  },

  shape: {
    position: 'absolute',
    top: -30,
    left: 0,
    transform: 'skewX(8deg) skewY(-2deg) translateY(25px) translateX(15px)',

    background: theme.palette.extras.oxfordBlueAlt,
    height: 30,
    width: '100%',
    margin: theme.spacing( 0 )
  },

  image: {
    margin: theme.spacing( 0.5 ),
    [theme.breakpoints.up( 'sm' )]: {}
  },

  clickable: {
    cursor: ( { alianza } ) => alianza ? 'pointer' : 'default'
  },

  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing( 4, 0, 6, 0 ),
    [theme.breakpoints.up( 'md' )]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
}) )
