import React from 'react'

import useStyles from './styles'
import { SectionContainer, SectionText, SectionTitle } from '../../_layout'
import { Grid } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'


const Certificaciones = ( { certificaciones } ) => {
  const styles = useStyles()

  const { imagenes, descripcion: { descripcion } } = certificaciones
  return (
      <SectionContainer sectionID={ 'certificaciones' }>
        <SectionTitle title={ 'Certificaciones' }/>
        <SectionText text={ descripcion }/>
        <Grid container className={styles.certificaciones}>
          {
            imagenes.map( ( imagen, i ) => (
                <GatsbyImage
                    key={ i }
                    alt={ 'imagen galeria' }
                    image={ imagen.gatsbyImageData }
                    className={ styles.imgCertificacion }/>
            ) )
          }
        </Grid>
      </SectionContainer>
  )
}

export default Certificaciones