import React from 'react'

import { SectionContainer, SectionText, SectionTitle } from '../../_layout'
import SectionDivider from '../../_layout/SectionDivider/SectionDivider'
import theme from '../../../App.theme'
import CardServicio from './CardServicio'
import { Grid } from '@material-ui/core'
import WhatsappContact from '../../_common/WhatsappContact/WhatsappContact'
import { TELEPHONE } from '../../../constants/navlinks'


const Servicios = ( { data } ) => {
  return (
      <SectionContainer sectionID="Experiencias" dark>
        <SectionTitle dark title="Experiencias"/>
        <SectionText
            light
            text="Vive las experiencias Neminatura al máximo, estas incluyen Senderismo, Experiencias gourmet y "/>

        <Grid container justifyContent={ 'center' } alignItems={ 'center' }>
          {
            data.map( ( { node } ) => {
              const { id, slug, resumen, titulo, imagenPrincipal } = node

              return <CardServicio key={ id } { ...{
                titulo,
                slug,
                slogan: resumen,
                imagen: imagenPrincipal
              } } />
            } )
          }
        </Grid>
        <WhatsappContact number={ TELEPHONE }
                         message={ '¡Hola! estoy interesado en vivir una experiencia Neminatura.' }/>

        <SectionDivider color={ theme.palette.text.disabled }/>
      </SectionContainer>
  )
}

export default Servicios