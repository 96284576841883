import { navigate } from 'gatsby'
import React from 'react'

import useStyles from './styles'
import { Grid, Typography } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'


const CardServicio = ( { titulo, slogan, imagen, slug } ) => {
  const styles = useStyles()

  return (
      <Grid className={ styles.cardRoot } onClick={() => navigate(`/experiencias/${slug}`)}>


        <Grid item xs className={ styles.imageContainer }>
          <Grid className={ styles.overlay }/>
          {
            imagen && <GatsbyImage
                alt={ titulo }
                image={ imagen.gatsbyImageData }
                className={ styles.productImage }/>
          }

          <Grid className={ styles.titulo }>
            <Typography component="span">{ slogan }</Typography>
          </Grid>
        </Grid>

        <Grid item className={ styles.resumen }>
          <Typography component="p" noWrap>{ titulo }</Typography>
        </Grid>


      </Grid>
  )
}

export default CardServicio