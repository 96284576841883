import { makeStyles } from '@material-ui/core'
import { MAX_WIDTH } from '../../_layout/Layout/Layout'


export default makeStyles( ( theme ) => ({
  productsRoot: {
    marginBottom: theme.spacing( 6 ),
    padding: theme.spacing( 4, 0 ),
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.up( 'sm' )]: {}
  },

  productSectionContainer: {
    width: '100%',
    padding: theme.spacing( 4, 0, 4, 4 ),
    maxWidth: MAX_WIDTH
  },

  colorContainer: {
    position: 'absolute',
    top: 0,
    left: '-5%',
    width: '110%',
    height: '100%',
    transform: 'rotate(2deg)',
    backgroundColor: theme.palette.extras.papayaWhip
  },

  decorationTriangle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '190px',
    height: '35px',
    background: '#fff',
    transform: 'translateY(-30px) rotate(-5deg)'
  },

  decorationTriangleBottom: {
    top: 'auto',
    left: 'auto',
    bottom: 0,
    right: 0,
    transform: 'translateY(30px) rotate(-5deg)'
  },

  productContent: {
    position: 'relative',
    width: '100%',
    zIndex: 10
  },

  decorationTop: {
    position: 'absolute',
    width: '30%',
    top: '-15%',
    right: '5%',
    transitionDelay: '.2s'
  },

  decorationBottom: {
    position: 'absolute',
    width: '30%',
    top: 'auto',
    right: 'auto',
    bottom: '-15%',
    left: '-5%',
    transitionDelay: '.2s',
  },

  productList: {
    paddingLeft: theme.spacing( 2 ),
    paddingBottom: theme.spacing( 4 ),
    marginRight: theme.spacing(2),
    transition: 'padding-left 2s cubic-bezier(.075,.82,.165,1) 1s',
    width: '100%',
    display: 'inline-flex',
    overflowX: 'auto',
    position: 'relative',

    listStyle: 'none',
    '& p': {
      width: '100%',
      marginTop: theme.spacing(1),
      fontSize: theme.typography.body2.fontSize
    }
  },

  productItem: {
    flex: '0 0 12rem',
    width: '12rem',
    overflow: 'hidden',
    height: theme.spacing( 30 ),
    background: theme.palette.background.paper,
    borderRadius: theme.roundnessSmall,
    boxShadow: theme.boxShadowShort,
    marginRight: theme.spacing( 2 ),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(  2 )
  },

  productImage: {
    width: '100%',
    height: theme.spacing( 18 ),
    borderRadius: theme.roundnessSmall
  }
}) )
