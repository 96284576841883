import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import { SectionTitle } from '../../_layout'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Button } from '../../_common'


const Productos = ( { productos } ) => {
  const styles = useStyles()

  return (
      <section className={ styles.productsRoot } id="productos">
        <Grid className={ styles.productSectionContainer }>
          <Grid className={ styles.productContent }>
            <Grid className={ styles.decorationTop }>
              <StaticImage src={ '../../../images/fish-top.png' }
                           placeholder="blurred"
                           alt="fish icon"
              />
            </Grid>
            <Grid className={ styles.decorationBottom }>
              <StaticImage src={ '../../../images/fish-bottom.png' }
                           placeholder="blurred"
                           alt="fish icon"
              />
            </Grid>
            <Grid style={ { position: 'relative' } }>
              <SectionTitle title="Descubre nuestros productos"/>
            </Grid>
            <ul className={ styles.productList }>
              {
                productos.map( ( { node } ) => {
                  const { id, imagen, titulo } = node

                  return <li key={ id } className={ styles.productItem }>
                    <Grid container>
                      <GatsbyImage
                          alt={ titulo }
                          image={ imagen.gatsbyImageData }
                          className={ styles.productImage }/>
                      <Typography component="p">{ titulo }</Typography>
                    </Grid>
                  </li>
                } )
              }
              <li className={ styles.productItem }>
                <Grid container justifyContent="center" alignItems="center" style={ { height: '100%' } }>
                  <Button
                      small
                      aria="Ver todos los productos"
                      goTo="/productos"
                  >
                    Todos los productos
                  </Button>
                </Grid>
              </li>
            </ul>
          </Grid>

          <Grid className={ styles.colorContainer }>
            <Grid className={ styles.decorationTriangle }/>
            <Grid className={ `${ styles.decorationTriangle } ${ styles.decorationTriangleBottom }` }/>
          </Grid>

        </Grid>
      </section>
  )
}

export default Productos