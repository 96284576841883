import React from 'react'

import { SectionContainer, SectionText, SectionTitle } from '../../_layout'
import SectionDivider from '../../_layout/SectionDivider/SectionDivider'
import theme from '../../../App.theme'


const text = 'Nemi Natura es una empresa 100% mexicana dedicada a la producción de alimentos naturales de alta calidad, respetando temporadas y con procesos productivos apegados al ambiente natural. También, producimos alimentos con la filosofía de respeto a la naturaleza y a los tiempos y ciclos propios de cada uno.'

const Nosotros = () => {

  return (
      <SectionContainer sectionID="nosotros">
        <SectionTitle title="Nosotros"/>
        <SectionText { ...{ text } } />
        <SectionDivider color={ theme.palette.text.disabled }/>
      </SectionContainer>
  )
}

export default Nosotros