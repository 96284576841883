import React from 'react'
import { Grid } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'

import useStyles from './styles'
import { SectionContainer, SectionTitle } from '../../_layout'


const Galeria = ( { imagenes } ) => {
  const styles = useStyles()

  if ( imagenes.length === 0 ) {
    return null
  }

  return (
      <SectionContainer sectionID={ 'galeria' }>
        <SectionTitle title={ 'Galería' }/>
        <Grid container className={ styles.galeria }>
          {
            imagenes.map( ( imagen, i ) => (
                <GatsbyImage
                    key={ i }
                    alt={ 'imagen galeria' }
                    image={ imagen.gatsbyImageData }
                    className={ styles.imgGaleria }/>
            ) )
          }
        </Grid>
      </SectionContainer>
  )
}

export default Galeria