import { makeStyles } from "@material-ui/core"

export default makeStyles((theme) => ({
  videoContainer: {
    overflow: "hidden",
    margin: theme.spacing(0, -4, 4, -4),
  },

  videoResponsive: {
    height: 0,
    overflow: "hidden",
    paddingBottom: "56.25%",
    paddingTop: "30px",
    position: "relative",
    "& iframe, object, embed": {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0
    },
  },
}))
