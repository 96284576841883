// noinspection DuplicatedCode

import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  root: {
    [theme.breakpoints.up( 'sm' )]: {

    }
  },

  certificaciones: {
    padding: theme.spacing(2, 0),
    justifyContent: 'center',
    alignItems: 'center'
  },


  imgCertificacion: {
    borderRadius: theme.roundnessSmall,
    width: '100%',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up( 'sm' )]: {
      width: '50%',
      margin: theme.spacing(2),
    }
  }
}) )
