import * as React from 'react'

import { SectionContainer } from '../../_layout'
import useStyles from './styles'


const VideoSection = ( { videoURL } ) => {
  const styles = useStyles()
  return (
      <SectionContainer sectionID="video-section">
        <div className={ styles.videoContainer }>
          <div className={ styles.videoResponsive }>
            <iframe
                title="Video de vimeo"
                src={ videoURL }
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
          </div>
        </div>
      </SectionContainer>
  )
}

export default VideoSection

